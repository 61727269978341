<template>
  <section v-editable="blok" reviews-carousel :data-style="blok?.style || 'center'">
    <div class="container">
      <div class="row">
        <h2 v-if="blok?.title && title != ''" class="section-title" v-html="title"></h2>
        <div v-if="blok?.description && description != ''" class="section-description" v-html="description"></div>
      </div>
      <div class="row">
        <swiper-container id="reviews-carousel-swiper" ref="containerRef" class="section-slider" :init="false">
          <swiper-slide v-for="review in blok.reviews" :key="review._uid">
            <StoryblokComponent :blok="review" />
          </swiper-slide>

          <div slot="container-end" class="slider-footer">
            <div class="slider-dots"></div>
            <div title="Slider Arrow" class="slider-arrows">
              <a href="#prev" title="Previous Slide" aria-label="Previous Slide" role="button" class="slider-arrow slider-prev">
                <span class="sr-only">Previous Slide</span>
              </a>
              <a href="#next" title="Next Slide" aria-label="Next Slide" role="button" class="slider-arrow slider-next">
                <span class="sr-only">Next Slide</span>
              </a>
            </div>
          </div>
        </swiper-container>
      </div>
    </div>
  </section>
</template>
<script setup>
  import { Navigation, Pagination, A11y } from "swiper/modules";
  import "swiper/element/css/a11y";
  import "swiper/element/css/navigation";
  import "swiper/element/css/pagination";

  const props = defineProps({ blok: Object });

  const title = computed(() => useRichText(props?.blok?.title || {}));
  const description = computed(() => useRichText(props?.blok?.description || {}));

  const a11y = {
    id: "reviews-carousel-swiper",
  };

  const navigation = {
    nextEl: "[reviews-carousel] .slider-next",
    prevEl: "[reviews-carousel] .slider-prev",
  };

  const breakpoints = {
    768: {
      slidesPerGroup: 1,
      slidesPerView: 1,
      dynamicBullets: false,
    },
    992: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    },
  };

  const pagination = {
    clickable: true,
    el: "[reviews-carousel] .slider-dots",
  };

  const containerRef = ref(null);
  const swiper = useSwiper(containerRef, {
    modules: [Navigation, Pagination, A11y],
    spaceBetween: 32,
    speed: 500,
    loop: false,
    rewind: true,
    slidesPerView: 1,
    sliesPerGroup: 1,
    dynamicBullets: true,
    dynamicMainBullets: 3,
    navigation,
    pagination,
    breakpoints,
    a11y,
  });

  onMounted(() => {
    if (swiper.instance) console.log("Swiper ready");
  });
</script>
<style src="~/assets/scss/section/reviews-carousel.scss" lang="scss"></style>
